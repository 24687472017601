.vessel-media-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 24px !important;
  transition: transform 0.2s linear 0s;

  .vessel-media-item {
    display: flex;
    align-items: center;
  }

  .elements-number {
    font-size: 14px;
  }
}

.vessel-media-item-container:hover {
  cursor: pointer;

  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);

  -webkit-transition: transform 0.05s ease-in-out;
  -moz-transition: transform 0.05s ease-in-out;
  -ms-transition: transform 0.05s ease-in-out;
  transition: transform 0.05s ease-in-out;
}