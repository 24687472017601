.main {
  display: flex;
}

.main-container {
  max-width: none !important;
  padding-top: 94px;
  padding-bottom: 30px;
  flex: 1 1 0%;
}
