.rdw-editor-wrapper {
    border: 1px solid rgb(196, 196, 196);
    border-radius: 4px;

    &:hover {
        border: 1px solid #273F82;
    }

    .rdw-editor-toolbar {
        border: none;
        margin-bottom: 0;
        border-bottom: 1px solid #F1F1F1;
        border-radius: 4px 4px 0 0;
    }

    .rdw-editor-main {
        border-radius: 0 0 4px 4px;
    }

    &.error {
        border-color: #d32f2f;
    }
}

.editor {
    background-color: white;
    min-height: 300px;
    padding: 16px 14px;
    color: black;
}