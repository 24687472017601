.tanks-template-form-container {
  color: #232735;
  font-size: 14px;
  padding-bottom: 150px;
  .tank-level-icon {
    fill: #d4d9e6;
    color: #273f82;
  }
}

.tank-level {
  cursor: pointer;
  transition: transform 0.2s linear 0s;
}
.tank-level-item {
  display: flex;
  justify-content: space-between;
}
.text-item {
  color: #232735;
}
