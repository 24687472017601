.auth-container {
    .auth-sign-in-header {
        padding: 32px;
        color: #232735;
    }

    .auth-forgot-password-header,
    .auth-confirm-reset-password-header {
        padding: 0 32px 32px 0;
        color: #232735;
    }

    .auth-confirm-reset-password-helper {
        padding: 0 32px 32px 0;
    }

    [data-amplify-router] {
        border: none;
        box-shadow: none;
    }

    [data-amplify-form] {

        .amplify-field-group {

            .amplify-input {
                height: 56px;
                border: 1px solid #CECFD2;

                &:focus {
                    box-shadow: none;
                }
            }

            .amplify-input[type=password] {
                border-right: none;
            }

            .amplify-field__show-password {
                border: 1px solid #CECFD2;

                &:focus {
                    box-shadow: none;
                }
            }

            .amplify-icon {
                color: #A9B2CD;
            }
        }

        .amplify-button--primary {
            background-color: #273F82;
        }


        .amplify-button--link {
            color: #273F82;
        }
    }
}