.card-container {
  transition: transform 0.2s linear 0s;

  .paper-content {
    padding: 37px 27px 37px 27px;
  }
  .icon {
    fill: #e7e7e9;
    color: #273f82;
  }
  .title {
    color: #1f3268;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-top: 14px;
  }
  .text {
    color: #545762;
    font-size: 12px;
    line-height: 20px;
    margin-top: 14px;
  }
  .paper-content:hover .icon {
    fill: #52659b;
    color: #ffff;
  }
  .corner-notification {
    width: fit-content;
    float: right;
    padding: 11px;
  }
}
.card-container:hover {
  cursor: pointer;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1.04);
}
