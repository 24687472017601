.container-vessel-form {
  color: #232735;
  font-size: 14px;
  padding-bottom: 84px;
  .vessel-icon {
    fill: #d4d9e6;
    color: #273f82;
  }
}
.vessel-edit-container {
  background-color: #e7e7e9;
  margin-top: 65px;
  border-radius: 8px;
  .vessel-edit-title {
    padding: 56px 0 47px 0;
  }
  .icon-title {
    fill: #d4d9e6;
    color: #273f82;
    margin-right: 17px;
  }
  .text-title {
    color: #273f82;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .action-buttons {
    width: 100%;
    margin-bottom: 20px;
  }
}
