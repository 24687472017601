.checklist {
  transition: transform 0.2s linear 0s;
}
.checklist-item {
  display: flex;
  justify-content: space-between;
}
.text-item {
  color: #232735;
}
.text-item-template {
  color: #273f82;
  font-weight: 700;
}

.checklist:hover {
  cursor: pointer;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(1.01);
}
