.status-slot {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 26px;
  border-radius: 8px;
}
.value-slot {
  font-weight: bold;
  font-size: 13px;
  line-height: 26px;
  padding: 8px;
}
