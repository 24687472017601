.leftContainer {
  font-size: 12px;
  color: #273f82;
  font-weight: 500;
}
.arrowIcon {
  width: 15px;
  height: 15px;
  color: #273f82;
  margin-right: 5px;
  margin-left: 5px;
}
.titlePage {
  font-size: 12px;
  color: #99a2c2;
}
.grid {
  padding-top: 5px;
  padding-right: 40px;
}
