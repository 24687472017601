.update-password-form {
  color: #232735;
  font-size: 14px;
  padding-bottom: 84px;

  .user-icon {
    fill: #d4d9e6;
    color: #273f82;
  }
}

.actionButton {
  width: 100%;
  margin-bottom: 20px;
}
