.file-uploader-component {
  margin: 22px 0 11px 0;
}

.file-uploader-input {
  color: #273f82;
  border: 1px dashed #939fc1;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 24px;
  width: 100%;
}

.file-uploader-input.error {
  color: #d32f2f;
  border: 1px dashed #d32f2f;
}

.button-upload {
  font-weight: 700;
}

.button-upload:focus {
  outline-offset: 4px;
}