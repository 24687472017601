.tag {
  background: #d4d9e6;
  width: auto;
  height: 34px;
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #17264e;
  font-size: 11px;
}
.spanText {
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
}
