.not-found-container {
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/images/404-page.svg');
  width: 600px;
  height: 600px;
  max-width: 600px !important;
  max-height: 600px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.page-number {
  color: #52659b;
  font-weight: bold;
  font-size: 100px;
  line-height: 30px;
  margin-top: 250px;
  margin-left: 330px;
}
.page-text {
  color: #a9b2cd;
  font-weight: normal;
  font-size: 27px;
  line-height: 22px;
  margin-top: 327px;
  margin-left: -192px;
}
.page-button {
  margin-top: 395px !important;
  width: 194px;
  margin-left: -189px !important;
}
