.template-box-container {
  margin-top: 71px;
  margin-bottom: 49px;
}
.complete-checklist-modal {
  color: #1f1f1f;
  font-size: 14px;
}
.group-fields-name {
  color: #232735;
  margin-top: 30px;
}
