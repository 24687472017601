@import '../../styles/abstracts/_variables.scss';

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - #{$header-height});
  overflow-y: auto;
}
.icon {
  color: #273f82;
}
.selectedItem .icon {
  color: #d4d9e6 !important;
}
.item:hover .icon {
  color: #d4d9e6;
}
