.tank-level-edit-container {
  margin-top: 71px;
  margin-bottom: 49px;
}
.tank-details-top-container {
  height: 50px;
  margin-bottom: 40px;
}
.modal-text-style {
  color: #1f1f1f;
  font-size: 14px;
}
