.slot {
  background: #d4d9e6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 28px;
  border-radius: 8px;
}
.number-slot {
  color: #273f82;
  font-size: 14px;
}
