.tanks-slider-container {
  height: 128px;
  background-color: #d4d9e6;
  border-radius: 8px;
  padding: 16px 24px;
}
.tanks-slider-title {
  color: #232735;
  font-size: 14px;
  font-weight: 700;
}
