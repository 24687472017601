.field-color-container {
  display: flex;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
}
