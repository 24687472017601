.delete-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-right: 40px;
  position: absolute;
  bottom: -6px;
  &-text {
    font-size: 12px;
    color: #ff7452;
  }
}

.field-type {
  margin-bottom: 0;
}
