@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import "../abstracts/variables";

h1 {
    font-size: 24px;
    line-height: 30px;

    &.regular {
        font-weight: $font-weight-regular;
    }

    &.medium {
        font-weight: $font-weight-medium;
    }

    &.bold {
        font-weight: $font-weight-bold;
    }
}

h2 {
    font-size: 18px;
    line-height: 30px;

    &.medium {
        font-weight: $font-weight-medium;
    }
}

label {

    &.regular {
        font-size: 14px;
        font-weight: $font-weight-regular;
    }

    &.bold {
        font-size: 14px;
        font-weight: $font-weight-bold;
    }

    &.small {
        font-size: 11px;
        font-weight: $font-weight-regular;
    }

    &.big {
        font-size: 15px;
        font-weight: $font-weight-regular;
    }
}