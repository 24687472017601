/* Primary palette */
$blue-navy-50: #273f82;
$black-yms-100: #0a0f1f;
$white: #ffffff;

/* Blue navy dark palette */
$blue-navy-90: #080d1a;
$blue-navy-80: #3b3f4c;
$blue-navy-70: #17264e;
$blue-navy-60: #1f3268;

/* Blue navy light palette */
$blue-navy-40: #52659b;
$blue-navy-30: #939fc1;
$blue-navy-20: #a9b2cd;
$blue-navy-10: #d4d9e6;

/* Black yms light palette */
$black-yms-90: #232735;
$black-yms-80: #3b3f4c;
$black-yms-70: #545762;
$black-yms-60: #6c6f79;
$black-yms-50: #85878f;
$black-yms-40: #9d9fa5;
$black-yms-30: #b6b7bc;
$black-yms-20: #cecfd2;
$black-yms-10: #e7e7e9;

/* Success palette */
$success-20: #79f2c0;
$success-30: #57d9a3;
$success-40: #36b37e;
$success-50: #00875a;

/* Error palette */
$error-20: #ff7452;
$error-30: #ff5630;
$error-40: #de350b;
$error-50: #bf2600;

/* Warning palette */
$warning-20: #ff7452;
$warning-30: #ffab00;
$warning-40: #ff991f;
$warning-50: #ff8b00;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;


$header-height: 94px;