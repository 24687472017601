.vessel-sections-title {
  margin-bottom: 36px;
  background-color: inherit;
}

.vessel-grid {
  color: #17264e;
  font-size: 24px;
  align-items: center;
}
