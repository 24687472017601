.image-upload {
  margin-top: 10px;
}
.image-upload > input {
  display: none;
}
.service-records-upload-button {
  padding: 8px;
  border: 1px solid #273f82;
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
}
.button-color-text {
  color: #273f82;
}
